<template>
  <div>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Opération réussie.</span>
      <v-btn text color="white" @click="snackbar = false">Fermer</v-btn>
    </v-snackbar>
    <v-dialog v-model="dialog">
      <template v-slot:activator="{ on }">
        <v-btn fab bottom right color="primary" fixed x-large v-on="on">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-alert v-if="error" type="error">
                <ul class="alert alert-danger">
                  <li v-for="(errorD, index) in error.details" :key="index">{{ errorD[0] }}</li>
                </ul>
              </v-alert>

              <v-col cols="12" sm="12" md="12"> 
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.last_name" label="Nom*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.first_name" label="Prénom*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.mobile" label="Mobile*" hide-details="auto" :rules="nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field v-model="editedItem.email" :label="$t('email')" hide-details="auto" ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.bank_account" label="Compte bancaire" hide-details="auto"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="close">Annuler</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="save">Enregistrer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Recherche" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table must-sort :headers="headers" :search="search" :items="teachers" :options.sync="options" :server-items-length="totalTeachers" :loading="loading" class="elevation-1">
        <template v-slot:item.email="{ item }">
          <a style="text-decoration: none;" v-if="item.email" v-bind:href="'mailto:' + item.email">
            <v-icon>mdi-email</v-icon>
          </a>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { APIServices } from '@/api/api';
export default {
  data: () => ({
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 4,
      sortBy: "last_name",
      totalItems: 0,
      rowsPerPageItems: [1, 2, 4, 8, 16]
    },
    genders: [
      {
        name: "Mister",
        key: "male"
      },
      {
        name: "Miss",
        key: "female"
      }
    ],
    dialog: false,
    file: null,
    search: "",
    nameRules: [v => !!v || "Champs obligatoire !"],
    headers: [
      { text: "Nom", value: "last_name" },
      { text: "Prénom", value: "first_name" },
      { text: "Mobile", value: "mobile" },
      { text: "Email", value: "email" },
      { text: "Compte bancaire", value: "bank_account" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    teachers: [],
    editedIndex: -1,
    editedItem: {
      last_name: null,
      first_name: null,
      email: null,
      mobile: null,
      bank_account: null
    },
    defaultItem: {
      last_name: null,
      first_name: null,
      email: null,
      mobile: null,
      bank_account: null
    },
    teacher: {
      name: ""
    },
    error: null,
    totalTeachers: 0,
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ["last_name"],
      sortDesc: [true]
    },
    loading: false,
    snackbar: false
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Ajout d'un enseignant" : "Modification d'un enseignant";
    }
  },

  watch: {
    dialog(val) {
      console.log('watch dialog :'+this.dialog)
      val || this.close();
    },
    options: {
      handler() {
        this.getDataFromApi().then(data => {
          this.teachers = data.items;
          this.totalTeachers = data.total;
        });
      },
      deep: true
    },
    search(val) {
      console.log(val);
      this.getDataFromApi().then(data => {
        this.teachers = data.items;
        this.totalTeachers = data.total;
      });
    }
  },
  mounted() {
    this.getDataFromApi().then(data => {
      this.teachers = data.items;
      this.totalTeachers = data.total;
    });
  },

  methods: {
    formatedDate(date) {
      return date ? this.$moment(date).format("Do MMMM YYYY") : "";
    },
    loadingTeachers() {
      this.getDataFromApi().then(data => {
        this.teachers = data.items;
        this.totalTeachers = data.total;
      });
    },
    getDataFromApi() {
      this.loading = true;
      return new Promise((resolve) => {
        
        /* let search = this.search.trim().toLowerCase();*/


        let items = this.getJsonData().then(response => {
          items = response.data;

         /* if (this.search) {
            items = items.filter(item => {
              return Object.values(item)
                .join(",")
                .toLowerCase()
                .includes(this.search);
            });
          }*/


          /* if (this.options.sortBy) {
            items = items.sort((a, b) => {
              const sortA = a[sortBy];
              const sortB = b[sortBy];

              if (descending) {
                if (sortA < sortB) return 1;
                if (sortA > sortB) return -1;
                return 0;
              } else {
                if (sortA < sortB) return -1;
                if (sortA > sortB) return 1;
                return 0;
              }
            });
          }*/

          /*if (rowsPerPage > 0) {
            items = items.slice((page - 1) * rowsPerPage, page * rowsPerPage);
          }*/

          const total = response.total;

          setTimeout(() => {
            this.loading = false;
            resolve({
              items,
              total
            });
          }, 300);
        });
      });
    },

    getJsonData() {
      //  Aqui pra ficar completo falta o parametro de filtro que a
      // a API so SWAPI não me da...
      var sortDesc = null;
      if (this.options.sortDesc[0]) {
        sortDesc = "asc";
      } else {
        sortDesc = "desc";
      }
      console.log(`/teachers?page=${this.options.page}&per_page=${this.options.itemsPerPage}&sort_by=${this.options.sortBy}&sort_desc=${sortDesc}&search=${this.search}`);
      return APIServices
        .get(`/teachers?page=${this.options.page}&per_page=${this.options.itemsPerPage}&sort_by=${this.options.sortBy}&sort_desc=${sortDesc}&search=${this.search}`)
        .then(function(response) {
          var result = response.data;
          //console.log(result);
          return result;
        })
        .catch(function(error) {
          // handle error
          console.log(error);
        });
    },

    updatePagination(pagination) {
      console.log("update:pagination", pagination);
    },
    loadData(file) {
      let self = this;
      if (file != undefined) {
        var reader = new FileReader();
        reader.onload = function(data) {
          data = reader.result;
          var xmlDoc;
          var parser;
          parser = new DOMParser();
          xmlDoc = parser.parseFromString(data, "text/xml");
          self.editedItem.gender = xmlDoc.getElementsByTagName("identity")[0].attributes["gender"].nodeValue;
          let dateofbirth = xmlDoc.getElementsByTagName("identity")[0].attributes["dateofbirth"].nodeValue;
          self.editedItem.birth_date = self.$moment(dateofbirth, "YYYYMMDD").format("YYYY-MM-DD");
          self.editedItem.last_name = xmlDoc.getElementsByTagName("name")[0].childNodes[0].nodeValue;
          self.editedItem.first_name = xmlDoc.getElementsByTagName("firstname")[0].childNodes[0].nodeValue;
          self.editedItem.address = xmlDoc.getElementsByTagName("streetandnumber")[0].childNodes[0].nodeValue;
          self.editedItem.zip = xmlDoc.getElementsByTagName("zip")[0].childNodes[0].nodeValue;
          self.editedItem.city = xmlDoc.getElementsByTagName("municipality")[0].childNodes[0].nodeValue;
          //self.editedItem.photo = "data:image/jpg;base64," + xmlDoc.getElementsByTagName("photo")[0].childNodes[0].nodeValue;
        };
        reader.readAsText(file, "UTF-8");
      }
    },
    initialize() {
      this.loading = true;
      APIServices
        .get("/teachers")
        .then(response => {
          console.log(response);
          this.teachers = response.data.data;
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    },

    editItem(item) {
      this.editedIndex = this.teachers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      confirm("Are you sure you want to delete this item?") && this.deleteTeacher(item);
    },
    deleteTeacher(item) {
      this.loading = true;
      APIServices
        .delete("/teachers/"+item.id)
        .then(response => {
          console.log(response);
          this.snackbar = true;
          this.loadingTeachers();
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
      this.file = null;
      this.error = null;
    },

    clear() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.file = null;
    },

    save() {
      this.loading = true;
      console.log(this.editedIndex);
      if (this.editedIndex > -1) {
        console.log("Mode edition");
        APIServices
          .put(`/teachers/` + this.editedItem.id, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response.data);
            this.teacher = response.data;
            Object.assign(this.teachers[this.editedIndex], this.editedItem);
            this.loading = false;
            this.close();
          })
          .catch(e => {
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      } else {
        console.log("Mode ajout");

        APIServices
          .post(`/teachers`, this.editedItem)
          .then(response => {
            this.snackbar = true;
            console.log(response);
            this.teacher = response.data;
            this.loadingTeachers();
            this.loading = false;
            this.close();
          })
          .catch(e => {
            this.error = e.response.data;
            console.log(e.response.data);
            this.loading = false;
          });
      }
      
    }
  }
};
</script>

<style></style>
